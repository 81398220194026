
import { Options, Vue } from 'vue-class-component'
import {
    YButton,
    YMenuItem,
    YInputText,
    YModal,
    YListChunk,
    YListItem,
    YList,
    YBreadcrumb,
    YForm,
    YTabs,
    YTabItem,
    YDropdown,
} from '@pacprotocol/yanui'

import LegalText from '@/components/Legal/LegalText.vue'

import data_sample_mn from '@/samples/masternode.json'

import Home from './Home.vue'

@Options({
    components: {
        YInputText,
        YButton,
        YDropdown,
        YTabs,
        YTabItem,
        YForm,
        YModal,
        YListChunk,
        YListItem,
        YList,
        YBreadcrumb,
    },
})
export default class Elements extends Vue {
    private text = ''
    private button_loading = false

    private modal_image_active = false
    private modal_card_active = false
    private modal_component_active = false
    private modal_component_example = Home
    private data_sample_masternode = data_sample_mn
    private dropdown_example = [
        {
            value: 'Apple',
            key: 'apple',
        },
        {
            value: 'Pineapple',
            key: 'pineapple',
        },
        {
            value: 'Strawberry',
            key: 'strawberry',
        },
    ]

    private modal_programmatic_open() {
        this.$yanui.modal.open({
            title: 'YModal Title',
            content: 'Weird',
            footer: {
                buttons: [
                    {
                        text: 'Cancel',
                        type: 'is-danger',
                        action: (modal: typeof YModal) => {
                            console.log('Cancel clicked')
                            modal.close()
                        },
                    },
                    {
                        text: 'Save',
                        type: 'is-success',
                        action: () => {
                            console.log('Save clicked')
                        },
                    },
                ],
            },
        })
    }

    private modal_legal() {
        this.$yanui.modal.open({
            instance: this,
            title: 'Legal',
            overflow: 'auto',
            component: LegalText,
            maxWidth: '500px',
            width: '500px',
            props: {
                privacy_policy: true,
                terms_of_service: true,
                updated: true,
            },
            canCancelBackground: false,
            canCancel: false,
            footer: {
                buttons: [
                    {
                        text: 'I Disagree',
                        type: 'is-ghost',
                        action: (modal: typeof YModal) => {
                            ;(this.$i ? this.$i : this).$plausible.trackEvent(
                                'Legal Disagree Warn',
                            )
                            this.$yanui.dialog.alert({
                                title: `Account & Files Termination`,
                                message:
                                    'If you decline the Terms of Service & Privacy Policy, all of your account information and files will be permanently deleted in 30 days. Within those 30 days you can still recover your account by logging in again and agreeing to the Terms of Service.\n\nAfter permanent deletion, you will no longer be able to use this service.\n\nAre you sure about that?',
                                icon: 'trash-alt',
                                type: 'is-danger',
                                maxWidth: '600px',
                                buttons: [
                                    {
                                        text: 'Abort',
                                        type: 'is-danger',
                                        action: (
                                            alert_modal: typeof YModal,
                                        ) => {
                                            alert_modal.close()
                                        },
                                    },
                                    {
                                        text: 'Yes, I disagree!',
                                        type: 'is-grey',
                                        action: async (
                                            alert_modal: typeof YModal,
                                        ) => {
                                            this.$plausible.trackEvent(
                                                'Legal',
                                                {
                                                    props: {
                                                        action: 'disagree',
                                                    },
                                                },
                                            )
                                            await this.$yandna.legal_disagree()
                                            this.$utils.logout().finally(() => {
                                                console.error(
                                                    '[LOGOUT]',
                                                    'success',
                                                )
                                                this.$router.replace({
                                                    name: 'Login',
                                                })
                                                alert_modal.close()
                                                modal.close()
                                            })
                                        },
                                    },
                                ],
                            })
                        },
                    },
                    {
                        text: 'I Agree',
                        type: 'is-success',
                        action: async (modal: typeof YModal) => {
                            this.$plausible.trackEvent('Legal', {
                                props: { action: 'agree' },
                            })
                            await this.$yandna.legal_agree()
                            if (
                                this.$store.state.redirect &&
                                this.$store.state.redirect.name
                            ) {
                                this.$router.push({
                                    name: this.$store.state.redirect.name,
                                })
                                this.$store.state.redirect = null
                            } else {
                                this.$router.push({ name: 'Files' })
                            }
                            modal.close()
                        },
                    },
                ],
            },
        })
    }

    private loading_click() {
        this.button_loading = true
        setTimeout(() => {
            this.button_loading = false
        }, 2000)
    }
}
